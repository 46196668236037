import { ISeo, SeoResponse } from '../../types/seo'

export const SEO_PAGE_TYPES = {
  NOT_FOUND: 'NotFound',
  PRODUCT_LIST_PAGE: 'ProductListPage',
  CATEGORY_PAGE: 'CategoryPage',
  PRODUCT_PAGE: 'ProductPage',
  ITEM_PAGE: 'ItemPage',
  VARIANT_PAGE: 'VariantPage',
  SEARCH_RESULT: 'SearchResult',
}

const getSEOConfigFromPayload = (response: SeoResponse<ISeo>, storeName: string) => {
  let seoconfig: ISeo
  if (!response || response.contents.length === 0) {
    seoconfig = {
      identifier: 'NotFound',
      page: {
        type: 'NotFound',
        title: storeName,
        identifier: 'NotFound',
      },
    }
  } else {
    const { tokenValue, tokenExternalValue } = response.contents[0]
    seoconfig = {
      ...response.contents[0],
      page: {
        tokenValue: tokenValue,
        externalContext: {
          identifier: tokenExternalValue,
        },
        ...response.contents[0].page,
      },
    }
  }
  const identifier = seoconfig.identifier
  return { [encodeURI(identifier)]: seoconfig }
}

export const useSeoComponent = (seoConfigRes: SeoResponse, storeName: string) => {
  return {
    seoConfig: getSEOConfigFromPayload(seoConfigRes, storeName),
  }
}
