import { useEffect, useState } from 'react'

export const useNavigateForward = () => {
  const [isForwardNavigation, setIsForwardNavigation] = useState(false)
  const [targetUrl, setTargetUrl] = useState<string>('')

  useEffect(() => {
    const handlePopState = () => {
      setIsForwardNavigation(true)
    }

    if (typeof window !== 'undefined') window.addEventListener('popstate', handlePopState)

    return () => {
      if (typeof window !== 'undefined') window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  useEffect(() => {
    if (isForwardNavigation && typeof window !== 'undefined') {
      const pdpUrl = window.location.href
      setTargetUrl(pdpUrl)
    }
  }, [isForwardNavigation])

  return {
    targetUrl,
    isForwardNavigation,
  }
}
