//Standard libraries
import React, { useEffect } from 'react'
import { usePathname } from 'next/navigation'

//UI
import {
  StyledTitle,
  StyledBodyContainer,
  StyledSubTitle,
  StyledContainer404,
  StyledParagraph,
  StyledButtonsContainer,
  StyledUnderParagraph,
} from './NotFound.style'
import { StyledLinkAsButton } from '../../components/UI'
import { sendNotFoundErrorEvent } from '../../foundation/analytics/tealium/lib'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useSite } from '../../foundation/hooks/useSite'
import { useTranslation } from 'next-i18next'
import useBreakpoints from '../../hooks/useBreakpoints'
import { isCanadaStore } from '../../utils/storeUtil'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '../../foundation/hooks/useAnalyticsData'


const NotFound: React.FC = () => {
  const pathname = usePathname()
  const { langCode } = useStoreIdentity()
  const { t } = useTranslation()
  const { isMobile } = useBreakpoints()
  const { mySite } = useSite()
  const analyticsData = useAnalyticsData(ANALYTICS_PAGE_TYPE.NOT_FOUND)

  useEffect(() => {
    sendNotFoundErrorEvent({
      common: analyticsData,
      requestedUrl: pathname,
    })
  }, [])

  return (
    <StyledContainer404>
      <StyledBodyContainer>
        <StyledTitle>{t('NotFound.Msgs.TitleContent')}</StyledTitle>
        <StyledSubTitle dangerouslySetInnerHTML={{ __html: t('NotFound.Msgs.TextContent') }} />
        <StyledParagraph dangerouslySetInnerHTML={{ __html: t('NotFound.Msgs.TextParagraph') }} />
        <StyledParagraph>{t('NotFound.Msgs.TextAboveCta')}</StyledParagraph>
        <StyledButtonsContainer hasBookEyeExam={isCanadaStore(mySite.locale)}>
          {isCanadaStore(mySite.locale) && (
            <StyledLinkAsButton href={`/${langCode}/${t('NotFound.Msgs.BookExamCtaUrl')}`}>
              {t('NotFound.Msgs.BookExamCta')}
            </StyledLinkAsButton>
          )}
          <StyledLinkAsButton href={`/${langCode}/${t('Common.Shop.Glasses.Url')}`}>
            {t('Common.Shop.Glasses.Cta')}
          </StyledLinkAsButton>
          <StyledLinkAsButton href={`/${langCode}/${t('Common.Shop.Contacts.Url')}`}>
            {t('Common.Shop.Contacts.Cta')}
          </StyledLinkAsButton>
        </StyledButtonsContainer>
        <StyledUnderParagraph dangerouslySetInnerHTML={{ __html: t(`NotFound.Msgs.NeedHelp${isMobile ? 'Mobile' : ''}`) }}/>
      </StyledBodyContainer>
    </StyledContainer404>
  )
}

export default NotFound
