import React from 'react'
import Head from 'next/head'
import { ISeo } from '@typesApp/seo'
import { getAlternateUrls } from '@foundation/hooks/useStoreIdentity'
import { useSelector } from 'react-redux'
import { hostnameSelector } from '@redux/selectors/site'
import { replaceSEODomain } from './SEO'
import config from '@configs/index'
import { getSite } from '@foundation/hooks/useSite'
import { generatePageTitle } from '@utils/helpers'

export interface HeadProps {
  seoAltUrl?: Record<string, string>
  c: ISeo
  host: string
}

export const HeadComponent: React.FC<HeadProps> = ({ seoAltUrl, c, host }) => {
  const mySite = getSite()
  const hostname = useSelector(hostnameSelector)

  const alternateHref = seoAltUrl && getAlternateUrls(seoAltUrl)
  const pageTitle = generatePageTitle(c.page.title, mySite?.locale)

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta name="description" content={c.page.metaDescription} />
      <meta name="keywords" content={c.page.metaKeyword} />
      {alternateHref &&
        Object.entries(alternateHref).map(([, value], i) => {
          const href = `https://${replaceSEODomain(value.key, hostname ?? '')}/${value.value}`
          return (
            <React.Fragment key={i}>
              {value.key !== 'en-ww' ? <link key={value.key} rel="alternate" hrefLang={value.key} href={href} /> : null}
              {value.key === config.defaultLocale && <link rel="alternate" hrefLang="x-default" href={href} />}
            </React.Fragment>
          )
        })}
      <title>{pageTitle}</title>
    </Head>
  )
}
