import styled from '@mui/material/styles/styled'

export const StyledContainer404 = styled('div', {
  name: 'NotFound',
  slot: 'container'
})(({ theme }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.background.light.primary,
  display: 'flex',
}))

export const StyledImage = styled('img', {
  name: 'NotFound',
  slot: 'image'
})(() => ({
  maxWidth: '50%',
}))

export const StyledBodyContainer = styled('div', {
  name: 'NotFound',
  slot: 'bodyContainer'
})(({ theme }) => ({
  position: 'relative',
  width: '100vw',
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(8),

  '& > a': {
    color: theme.palette.text.dark.primary,
  },

  [theme.breakpoints.up('sm')]: {
    width: '80vw',
    margin: '0 auto',
    padding: theme.spacing(12),
  },

  '& > :nth-of-type(2)': {
    marginBottom: theme.spacing(4),
  },
}))

export const StyledTitle = styled('h2', {
  name: 'NotFound',
  slot: 'title'
})(({ theme }) => ({
  fontSize: theme.spacing(18),
  color: theme.palette.text.dark.secondary,
  fontWeight: 900,
  marginTop: 0,
  lineHeight: 1,
  textTransform: 'uppercase',
  marginBottom: theme.spacing(3)
}))

export const StyledSubTitle = styled('p', {
  name: 'NotFound',
  slot: 'subTitle'
})(({ theme }) => ({
  width: theme.spacing(48),
  fontSize: theme.spacing(5),
  textAlign: 'center',
  color: theme.palette.text.dark.primary,

  '& > p:first-of-type': {
    marginBottom: '40px',
  },

  '& > a': {
    fontSize: '18px',
    lineHeight: 1.78,
    textDecoration: 'underline',
    cursor: 'pointer',
  },

  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(80),
    fontSize: theme.spacing(6),
  },

  [theme.breakpoints.up('md')]: {
    width: theme.spacing(108),
  },
}))

export const StyledParagraph = styled('p', {
  name: 'NotFound',
  slot: 'paragraph'
})(({ theme }) => ({
  maxWidth: '620px',
  fontSize: theme.spacing(4),
  textAlign: 'center',
  color: theme.palette.text.dark.primary,
}))

export const StyledButtonsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasBookEyeExam',
  name: 'NotFound',
  slot: 'buttonsContainer',
})<{ hasBookEyeExam: boolean }>(({ theme, hasBookEyeExam }) => ({
  position: 'relative',
  display: 'grid',
  gap: theme.spacing(4),
  marginTop: theme.spacing(6),

  a: {
    minHeight: '40px',
    height: 'auto',
    textAlign: 'center',
    margin: 0,
    maxWidth: theme.spacing(70),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5)
  },

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: hasBookEyeExam ? '1fr 1fr 1fr' : '1fr 1fr',
    gap: theme.spacing(8),
  },
}))

export const StyledUnderParagraph = styled('p', {
  name: 'NotFound',
  slot: 'underParagraph'
})(({ theme }) => ({
  marginTop: theme.spacing(12),
  fontSize: theme.spacing(3),
  textAlign: 'center',
  color: theme.palette.text.dark.primary,
  minWidth: '620px',

  a: {
    color: theme.palette.custom.dark1.blue,
    cursor: 'pointer',
    textDecoration: 'underline',
    fontWeight: 'bold',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))
